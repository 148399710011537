import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import SEO from "../../components/Seo";
import CardContainer from "../../components/CardContainer";
import CategoryList from "../../components/CategoryList";
import { useAlternateLangs } from "../../components/Hreflangs";

const StyledMiddle = styled.div`
  display: flex;
  padding: 0vmin 1vmin;

  @media all and (max-width: 540px) {
    flex-direction: column;
    justify-content: center;
  }
`;

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/blog"
);

const Blog = function ({ data, location }) {
  if (!data) return <p>Damn! Something went wrong. No data found!</p>;

  // create list of unique blog categories
  // first getting all strings with categories
  const allCategoryStrings = [];
  data.allMdx.edges.map((item, i) =>
    // splitting and trimming string
    item.node.frontmatter.tags.split(",").map(category => {
      // splitting at : to split pathnames of tags away
      category = category.split(":")[0];
      return allCategoryStrings.push(category.trim());
    })
  );
  // new unique Category list in alphabetical order
  const uniqueCategories = Array.from(new Set(allCategoryStrings)).sort();

  return (
    <Layout location={location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Blog für Suchmaschinenoptimierung und Analytics"
          lang="de"
          description="Lies über SEO und Web Analytics für das Web!"
          alternateLangs={alternateLangs}
        />
        <MainContent maxWidth="1000">
          <StyledMiddle>
            <CardContainer data={data} />
            <CategoryList data={uniqueCategories} />
          </StyledMiddle>
        </MainContent>
      </React.Fragment>

    </Layout>
  );
};

export default React.memo(Blog);

export const pageQuery = graphql`
  query BlogPageGermanPosts {
    allMdx(
      filter: { fields: { collection: { eq: "post" } }, frontmatter: { language: { eq: "de" } } }
      sort: { fields: [frontmatter___publish], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          fields {
            relativePath
            collection
            slug
          }
          frontmatter {
            title
            description
            publish(formatString: "DD. MMM YYYY")
            image {
              name
              relativePath
            }
            tags
            language
          }
        }
      }
    }
  }
`;
